@import '../../vars';

div.list-pages {
  position: absolute;
  top: 50px;
  right: 0;
  border: 1px solid $gray-color;

  display: flex;
  flex-direction: column;
  background: white;

  a {
    padding: 10px 5px;
    width: 200px;
    text-align: right;
    margin: 0 10px;
    border-bottom: 1px solid $gray-color;

    &:last-child {
      border: none;
    }
  }
}

.NavBar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 50px;
  border-bottom: 1px solid $gray-color;
  padding-bottom: 10px;


  .items {
    display: flex;
    align-items: center;
    position: relative;
    gap: 33px;
    margin-top: 8px;
    margin-left: 7px;

    p {
      padding: 3.5px;
    }

    a {
      font-size: 22px;
      
      &.email {
        color: black;
        @media screen and (max-width: $contents-breakpoint-3) {
          font-size: 30px;
        }
      }

      &.facebook {
        color: rgb(11, 91, 219);
        @media screen and (max-width: $contents-breakpoint-3) {
          font-size: 30px;
        }
      }

      &.youtube {
        color: red;
        @media screen and (max-width: $contents-breakpoint-3) {
          font-size: 30px;
        }
      }
    }
  }

  .logo {
    display: flex;
    flex-direction: row;

    img {
      width: 40px;
      height: 40px;
      margin-right: 5px;

      @media screen and (max-width: $contents-breakpoint-3) {
        width: 30px;
        height: 30px;
      }
    }

    .site-name {
      display: flex;
      align-items: center;
      flex-direction: column;
      margin-top: 3px;

      h3 {
        font-size: 10px;

        @media screen and (max-width: $contents-breakpoint-3) {
          font-size: 7px;
        }
      }

      h2 {
        font-size: 14px;

        @media screen and (max-width: $contents-breakpoint-3) {
          font-size: 10px;
        }

        margin-top: 2px;
      }
    }
  }

  .items_1 {
    display: flex;
    font-size: 12px;
    font-weight: 700;
    margin-left: 18px;
    font-style: italic;
  }
}